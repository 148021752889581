import React, { useEffect, useState } from "react";
import {
  StyledLink,
  DownloadText
} from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/Question/styles";
import { Container, FlexContainer } from "components/Atoms/Containers";
import downloadIcon from "assets/downloadIcon";

/**
 * VideoResponse component renders a list of video files and provides download links for each video.
 */
const VideoResponse = ({ answer, attemptId }) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const fileName = `QUESTION_${answer.question_upload_id}_ATTEMPT_${attemptId}.webm`;

  useEffect(() => {
    if (answer?.files && answer.files.length > 0) {
      const fetchVideo = async gcs_link => {
        try {
          const response = await fetch(gcs_link);
          if (!response.ok) throw new Error("Network response was not ok");
          const blob = await response.blob();
          return blob;
        } catch (error) {
          console.error("Failed to fetch video:", error);
          return null;
        }
      };

      const firstFile = answer.files[0];
      let objectUrl = null;

      fetchVideo(firstFile.gcs_link).then(videoBlob => {
        if (videoBlob) {
          objectUrl = URL.createObjectURL(videoBlob);
          setVideoUrl(objectUrl);
        }
      });

      return () => {
        if (objectUrl) URL.revokeObjectURL(objectUrl);
      };
    }
  }, [answer]);

  return (
    <FlexContainer direction={"column"} gap={"16"}>
      {answer?.files && answer.files.length > 0 ? (
        answer.files.map(file => {
          return (
            <FlexContainer key={file.id} direction={"column"} gap={"16"}>
              <video controls width="350" role="video">
                <track default kind="captions" srcLang="en" />
                {videoUrl && <source src={videoUrl} type="video/mp4" />}
                There is an issue with the recorded video.
              </video>
              <StyledLink
                href={videoUrl}
                download={fileName}
                mediaType="video/mp4"
              >
                <Container margin="-2px 0 0">{downloadIcon}</Container>
                <DownloadText>Download video ({fileName})</DownloadText>
              </StyledLink>
            </FlexContainer>
          );
        })
      ) : (
        <p>Invalid video link or video not available.</p>
      )}
    </FlexContainer>
  );
};

export { VideoResponse };
