import React from "react";
import { questionTypes } from "pages/App/helpers/questionTypes";
import BandedResponse from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/BandedResponse";
import {
  TitleContainer,
  QuestionNumber,
  QuestionText,
  DownloadText,
  StyledLink
} from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/Question/styles";
import SelectOptions from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/SelectOptions";
import { VideoResponse } from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/VideoResponse";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { FlexContainer, Container } from "components/Atoms/Containers";
import { Row, Cell } from "components/Blocks/Styled/Tables";
import downloadIcon from "assets/downloadIcon";

const Question = ({ attempt, formattedTimeTaken, index }) => {
  const attemptId = window.location.pathname.split("/").pop();
  const { answer, question } = attempt;
  const isVideoQuestion = question?.accept_types?.includes("video");

  return (
    <tbody key={attempt.id}>
      <Row padding="32px 0 0 16px">
        <Cell colSpan="2">
          <TitleContainer className="bold">
            {attempt.question.content[0].title}
          </TitleContainer>
        </Cell>
      </Row>
      <Row>
        <Cell colSpan="2">
          <FlexContainer>
            <QuestionNumber textTransform="uppercase">
              Q{index + 1}
            </QuestionNumber>
            <FlexContainer direction="column" width="100%">
              <FlexContainer direction="column">
                <QuestionText margin="0 0 6px">
                  {parseHtml(attempt.question.content[0].text_display, true)}
                </QuestionText>
              </FlexContainer>
              {attempt.question_type === questionTypes.BANDED && (
                <BandedResponse
                  attempt={attempt}
                  formattedTimeTaken={formattedTimeTaken}
                />
              )}
              {attempt.question_type === questionTypes.SELECT && (
                <SelectOptions
                  attempt={attempt}
                  formattedTimeTaken={formattedTimeTaken}
                />
              )}
              {attempt.question_type === questionTypes.UPLOAD &&
                !isVideoQuestion && (
                  <FlexContainer align="center" margin="0px 0px 16px 0px">
                    <StyledLink
                      href={answer?.files?.[0]?.gcs_link || ""}
                      download="Question_Submission"
                    >
                      <Container margin="-2px 0 0">{downloadIcon}</Container>
                      <DownloadText>Download Submission</DownloadText>
                    </StyledLink>
                  </FlexContainer>
                )}
              {isVideoQuestion && (
                <VideoResponse answer={answer} attemptId={attemptId} />
              )}
            </FlexContainer>
          </FlexContainer>
        </Cell>
      </Row>
    </tbody>
  );
};

export default Question;
